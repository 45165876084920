<template>
  <div class="chat-room-container">
    <!-- 左侧边栏：显示在线用户列表 -->
    <div class="sidebar">
      <div class="room-header">
        <h2>聊天室</h2>
      </div>
      <!-- 在线用户列表区域 -->
      <div class="user-list">
        <h3>在线用户</h3>
        <!-- 遍历显示所有在线用户 -->
        <div class="user-item" v-for="user in onlineUsers" :key="user.senderid">
          <!-- 用户头像 -->
          <div class="user-avatar">
            <el-avatar :size="40" :src="user.avatar" icon="el-icon-user-solid"></el-avatar>
          </div>
          <!-- 用户信息 -->
          <div class="user-info">
            <div class="user-name">{{ user.senderName }}</div>
            <!-- 用户在线状态 -->
            <div class="user-status" :class="{ 'online': true }">
              在线
            </div>
          </div>
          <!-- 用户操作按钮 -->
          <div class="user-actions">
            <el-button size="mini" type="primary" icon="el-icon-phone" circle @click="startVoiceCall(user)"></el-button>
          </div>
        </div>
      </div>
    </div>

    <!-- 右侧聊天区域 -->
    <div class="chat-container">
      <!-- 聊天头部：显示当前房间名称和通话状态 -->
      <div class="chat-header">
        <h2>{{ currentRoom.name }}</h2>
        <!-- WebSocket连接状态显示 -->
        <div class="connection-status" :class="{'status-connected': isConnected, 'status-disconnected': !isConnected}">
          <i class="el-icon-connection"></i>
          <span>{{ isConnected ? '已连接' : '未连接' }}</span>
          <el-button v-if="!isConnected" 
                    size="mini" 
                    type="danger" 
                    @click="forceReconnect">重新连接</el-button>
          <el-button v-if="!isConnected && reconnectAttempts > 3" 
                    size="mini" 
                    type="warning" 
                    @click="refreshPage">刷新页面</el-button>
        </div>
        <div class="call-status" v-if="callStatus">
          <span>{{ callStatusText }}</span>
          <el-button size="mini" type="danger" @click="endCall" v-if="isInCall">结束通话</el-button>
        </div>
      </div>

      <!-- 音频元素：用于处理本地和远程音频流 -->
      <audio ref="localAudio" muted></audio>
      <audio ref="remoteAudio" autoplay></audio>

      <!-- 消息列表容器 -->
      <div class="message-container" ref="messageContainer">
        <!-- 遍历显示所有消息 -->
        <div v-for="(message, index) in messages" :key="index"
             :class="['message-item', message.senderid === currentUser.id ? 'self-message' : '']">
          <!-- 消息发送者头像 -->
          <div class="message-avatar">
            <el-avatar :size="40" :src="message.avatar" icon="el-icon-user-solid"></el-avatar>
          </div>
          <!-- 消息内容 -->
          <div class="message-content">
            <div class="message-name">{{ message.senderName }}</div>
            <div class="message-text">{{ message.content }}</div>
            <div class="message-time">{{ formatTime(message.sendTime) }}</div>
          </div>
        </div>
      </div>

      <!-- 语音通话界面 -->
      <div class="voice-call-container" v-if="isInCall">
        <!-- 远程用户视频流 -->
        <div class="remote-stream" v-if="remoteStreamActive">
          <div class="remote-user">{{ callPartner.nickname }}</div>
        </div>
        <!-- 本地用户控制面板 -->
        <div class="local-stream">
          <div class="call-controls">
            <!-- 麦克风开关按钮 -->
            <el-button type="primary" :icon="isMuted ? 'el-icon-turn-off-microphone' : 'el-icon-microphone'"
                       circle @click="toggleMute"></el-button>
            <!-- 结束通话按钮 -->
            <el-button type="danger" icon="el-icon-phone" circle @click="endCall"></el-button>
          </div>
        </div>
      </div>

      <!-- 消息输入区域 -->
      <div class="chat-input">
        <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入消息"
            v-model="messageText"
            @keyup.enter.native="sendMessage"
        ></el-input>
        <div class="input-actions">
          <el-button type="primary" @click="sendMessage">发送</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import globle from "../../globle";

export default {
  name: 'ChatRoom',
  
  // # == 组件数据模块 ==
  // 组件数据定义
  // 描述：定义组件内部的响应式数据
  data() {
    return {
      // 聊天相关数据
      messageText: '',       // 消息输入框的内容
      messages: [],          // 聊天消息列表
      onlineUsers: [],       // 在线用户列表
      currentUser: {},       // 当前用户信息
      currentRoom: {id: 'default', name: '公共聊天室'},  // 当前聊天室信息
      lastmessgaeid: "",     // 最后一条消息ID，用于去重

      // WebRTC相关状态
      // 用于管理语音通话功能的各种状态和对象
      peerConnection: null,      // WebRTC连接对象，负责P2P通信
      localStream: null,         // 本地音频流，来自用户麦克风
      remoteStream: null,        // 远程音频流，来自通话对方
      callStatus: '',            // 通话状态：calling(呼叫中)、incoming(来电)、connected(已连接)
      isInCall: false,           // 是否在通话中的标志
      isMuted: false,            // 麦克风是否静音
      callPartner: null,         // 通话对方信息
      remoteStreamActive: false, // 远程流是否激活的标志
      
      // WebSocket相关
      // 用于管理与聊天服务器的实时连接
      socket: null,              // WebSocket连接对象
      isConnected: false,        // 是否已连接到服务器
      lastReceiveTime: 0,        // 最后接收消息的时间戳，用于检测连接活跃性
      heartbeatInterval: null,   // 心跳定时器，定期发送心跳包
      heartbeatSupportConfirmed: false, // 服务器是否支持标准心跳机制
      heartbeatFailCount: 0,     // 心跳失败计数，连续失败超过阈值则重连
      fallbackHeartbeatMode: false, // 是否使用备用心跳模式
      reconnectAttempts: 0,      // 重连尝试次数，用于实现指数退避
      reconnectTimer: null,      // 重连定时器，控制重连间隔
    };
  },

  // # == 计算属性模块 ==
  // 计算属性
  computed: {
    // 通话状态文本显示
    // 描述：根据当前通话状态返回对应的中文提示文本
    // 返回值：字符串，表示当前通话状态的文字描述
    callStatusText() {
      // 根据callStatus状态返回对应的文本提示
      // 使用switch语句处理不同的状态情况
      switch (this.callStatus) {
        case 'calling':
          // 呼叫中状态：显示"正在呼叫xx..."
          return `正在呼叫 ${this.callPartner?.nickname}...`;
        case 'incoming':
          // 来电状态：显示"xx邀请您进行语音通话"
          return `${this.callPartner?.nickname} 邀请您进行语音通话`;
        case 'connected':
          // 已连接状态：显示"与xx的通话中"
          return `与 ${this.callPartner?.nickname} 的通话中`;
        default:
          // 其他状态：返回空字符串
          return '';
      }
    }
  },

  // 组件方法
  methods: {
    // # == WebSocket连接模块 ==
    // 初始化WebSocket连接
    // 描述：创建与聊天服务器的WebSocket连接并设置相关事件处理
    // 参数：无参数
    // 返回值：无返回值
    // 核心逻辑：
    // > 步骤1: 创建WebSocket连接对象
    // > 步骤2: 设置连接建立、消息接收、连接关闭和错误处理函数
    // > 步骤3: 连接成功后发送用户信息并初始化心跳机制
    initSocket() {
      // 创建WebSocket连接
      // 使用安全的WSS协议连接到聊天服务器
      // this.socket = new WebSocket('wss://api.psnsgame.com/ws/chat');
      // 本地开发时可使用以下地址
      this.socket = new WebSocket(globle.websockets);
      
      // 增加重连尝试计数
      // 记录尝试连接的次数，用于实现指数退避重连策略
      this.reconnectAttempts++;
      console.log(`第${this.reconnectAttempts}次尝试连接`);
      
      // 连接建立时的处理
      // 当WebSocket连接成功建立时执行
      this.socket.onopen = () => {
        // 设置连接状态为已连接
        this.isConnected = true;
        console.log('WebSocket连接已建立');
        
        // 发送用户信息到服务器
        // 通知服务器当前用户加入聊天
        this.sendUserInfo();
        
        // 初始化心跳机制
        // 启动定期发送心跳包的机制，保持连接活跃
        this.initHeartbeat();
      };

      // 接收消息的处理
      // 当从服务器接收到WebSocket消息时执行
      this.socket.onmessage = (event) => {
        try {
          // 更新最后接收消息的时间
          // 记录时间戳用于心跳超时检测
          this.lastReceiveTime = Date.now();
          
          // 解析消息数据
          // 将接收到的JSON字符串解析为JavaScript对象
          const data = JSON.parse(event.data);
          
          // 处理心跳响应
          // 如果是心跳回应消息，直接更新心跳状态并返回
          if (data.isHeartbeat === true || data.type === 'PONG' || data.type === 'HEARTBEAT') {
            console.log('收到心跳响应');
            
            // 确认服务器支持心跳
            // 标准心跳响应表明服务器支持心跳机制
            if (data.type === 'PONG' || data.type === 'HEARTBEAT') {
              this.heartbeatSupportConfirmed = true;
              this.fallbackHeartbeatMode = false;
            }
            
            // 重置心跳失败计数
            // 收到响应表明连接正常，重置失败计数器
            this.heartbeatFailCount = 0;
            return;
          }
          
          // 处理其他类型的消息
          // 调用专门的消息处理函数
          this.handleSocketMessage(data);
        } catch (error) {
          // 解析消息失败时的错误处理
          console.error('解析消息失败:', error);
        }
      };

      // 连接关闭时的处理
      this.socket.onclose = () => {
        this.isConnected = false;
        console.log('WebSocket连接已关闭');
        
        // 停止心跳
        this.stopHeartbeat();
        
        // // 尝试重新连接
        // // 注释：自动重连机制
        // // > 连接关闭后自动尝试重新连接
        // // > 使用随机延迟避免同时重连导致服务器负载过高
        // // > 重连延迟随着尝试次数增加而增加
        // const reconnectDelay = Math.min(30000, 2000 + this.reconnectAttempts * 1000 + Math.random() * 3000);
        //
        // this.reconnectTimer = setTimeout(() => {
        //   if (!this.isConnected && this.reconnectAttempts < 10) {
        //     console.log(`尝试重新连接...延迟${reconnectDelay}ms`);
        //     this.initSocket();
        //   } else if (this.reconnectAttempts >= 10) {
        //     console.log('多次重连失败，停止自动重连');
        //     // 显示重连失败提示
        //     this.$message.error('多次连接失败，请检查网络或刷新页面');
        //   }
        // }, reconnectDelay);
      };

      // 连接错误时的处理
      this.socket.onerror = (error) => {
        console.error('WebSocket错误:', error);
      };
    },
    
    // 初始化心跳机制
    // 注释：WebSocket心跳
    // > 定期发送小数据包保持连接活跃
    // > 检测服务器是否响应，及时处理连接断开
    initHeartbeat() {
      // 创建心跳数据
      // 储存最后接收消息的时间
      this.lastReceiveTime = Date.now();
      this.heartbeatFailCount = 0;
      this.heartbeatSupportConfirmed = false;
      
      // 清除可能存在的旧心跳定时器
      if (this.heartbeatInterval) {
        clearInterval(this.heartbeatInterval);
      }
      
      // 设置心跳定时器 - 每20秒发送一次心跳
      // 注释：心跳频率
      // > 20秒发送一次，低于大多数网络设备的60秒超时时间
      // > 太频繁会增加网络负担，太少则可能无法防止超时
      this.heartbeatInterval = setInterval(() => {
        // 检查连接状态
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
          // 发送心跳包
          if (this.fallbackHeartbeatMode) {
            // 使用备用心跳模式 - 简单的空消息保持连接
            // 注释：备用心跳模式
            // > 当服务器不支持标准心跳时使用
            // > 发送一个无害的空消息以保持连接活跃
            this.sendFallbackHeartbeat();
          } else {
            // 使用标准PING/PONG心跳
            this.sendPing();
          }
          
          // 检查是否超时未收到响应
          // 注释：连接活跃性检测
          // > 如果超过45秒未收到任何消息，判断连接可能已断开
          // > 主动关闭并重新连接
          const now = Date.now();
          if (now - this.lastReceiveTime > 45000) { // 45秒超时
            console.log('超过45秒未收到消息，连接可能已断开');
            
            // 心跳失败次数增加
            this.heartbeatFailCount++;
            
            // 如果多次心跳失败但连接仍然存在，可能是服务器不支持心跳
            if (this.heartbeatFailCount >= 2 && !this.fallbackHeartbeatMode) {
              console.log('服务器可能不支持标准心跳，切换到备用模式');
              this.fallbackHeartbeatMode = true;
              // 重置计时器，给备用模式一个机会
              this.lastReceiveTime = Date.now();
            } 
            // 连续多次失败，连接确实有问题
            else if (this.heartbeatFailCount >= 3) {
              console.log('多次心跳检测失败，主动断开重连');
              this.socket.close();
              
              // 立即尝试重连
              this.isConnected = false;
              this.stopHeartbeat();
              setTimeout(() => {
                this.initSocket();
              }, 1000);
            }
          }
        } else {
          // 连接已关闭，停止心跳
          this.stopHeartbeat();
          
          // 如果未连接，尝试重连
          if (!this.isConnected) {
            this.initSocket();
          }
        }
      }, 20000); // 20秒发送一次心跳
      
      // 立即发送第一次心跳以检测连接
      this.sendPing();
    },
    
    // 停止心跳机制
    // 注释：清理心跳定时器
    // > 释放资源，避免内存泄漏
    stopHeartbeat() {
      if (this.heartbeatInterval) {
        clearInterval(this.heartbeatInterval);
        this.heartbeatInterval = null;
      }
    },
    
    // 发送Ping心跳包
    // 注释：心跳数据包
    // > 发送最简单的JSON数据作为心跳
    // > 服务器应当回复相应的PONG消息
    sendPing() {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        // 发送心跳包
        this.socket.send(JSON.stringify({
          type: 'PING',
          timestamp: Date.now()
        }));
        console.log('发送心跳包');
      }
    },

    // 发送用户信息到服务器
    sendUserInfo() {
      if (this.isConnected) {
        this.socket.send(JSON.stringify(
            {
              type: 'JOIN',
              senderid: this.currentUser.id,
              senderName: this.currentUser.nickname,
              avatar: this.currentUser.avatarUrl
            }
        ));
      }
    },

    // 处理WebSocket消息
    handleSocketMessage(data) {
      console.log(data)
      
      // 心跳响应处理
      if (data.isHeartbeat === true || data.type === 'PONG' || data.type === 'HEARTBEAT') {
        console.log('收到心跳响应');
        
        // 确认服务器支持心跳
        if (data.type === 'PONG' || data.type === 'HEARTBEAT') {
          this.heartbeatSupportConfirmed = true;
          this.fallbackHeartbeatMode = false;
        }
        
        // 重置心跳失败计数
        this.heartbeatFailCount = 0;
        return;
      }
      
      switch (data.type) {
        case 'user_list':
          // 更新在线用户列表
          this.onlineUsers = JSON.parse(data.content);
          break;
        case 'USER':
          // 处理新消息
          if (data.isHeartbeat) return;
          
          if(data.id!==this.lastmessgaeid ){
            this.messages.push(data);
          }
          this.$nextTick(() => {
            this.scrollToBottom();
          });
          break;
        case 'call_offer':
          this.handleCallOffer(data);
          break;
        case 'call_answer':
          this.handleCallAnswer(data);
          break;
        case 'ice_candidate':
          this.handleIceCandidate(data);
          break;
        case 'call_end':
          this.handleCallEnd();
          break;
      }
    },

    // # == 消息发送模块 ==
    // 发送用户消息到聊天室
    // 注意：关键提示
    // 该函数处理用户输入的消息，创建消息对象并通过WebSocket发送
    sendMessage() {
      // 检查消息是否为空，避免发送空消息
      if (!this.messageText.trim()) return;
      
      // 生成唯一的消息ID并保存，用于防止消息重复显示
      this.lastmessgaeid = this.generateMessageId();
      
      // 创建消息对象
      // 包含消息ID、类型、内容、发送者信息和发送时间
      const message = {
        id: this.lastmessgaeid,
        type: "USER",
        content: this.messageText,
        senderid: this.currentUser.id,
        senderName: this.currentUser.nickname,
        avatar: this.currentUser.avatarUrl,
        sendTime: new Date().toISOString()
      };

      // 发送消息到服务器
      // 检查WebSocket连接状态，确保连接有效
      if (this.isConnected) {
        this.socket.send(JSON.stringify(message));
      }

      // 添加到本地消息列表
      // 不等待服务器响应，立即在界面显示消息
      this.messages.push(message);
      
      // 清空输入框，准备下一条消息
      this.messageText = '';
      console.log(this.messages);
      
      // 在下一个DOM更新周期滚动到底部
      // 确保新消息显示在可视区域
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },

    // 滚动到消息列表底部
    scrollToBottom() {
      const container = this.$refs.messageContainer;
      container.scrollTop = container.scrollHeight;
    },

    // 格式化时间显示
    formatTime(isoString) {
      const date = new Date(isoString);
      return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    },

    // # == WebRTC语音通话模块 ==
    // WebRTC相关方法
    
    // 开始语音通话
    // 描述：初始化并发起与指定用户的WebRTC语音通话
    // 参数：user - 要呼叫的用户对象，包含用户ID和昵称等信息
    // 返回值：无返回值
    // 核心逻辑：
    // > 步骤1: 设置呼叫状态和保存呼叫对象信息
    // > 步骤2: 获取本地音频流
    // > 步骤3: 创建WebRTC连接
    // > 步骤4: 创建并发送通话请求(offer)
    async startVoiceCall(user) {
      // 保存呼叫对象信息并设置状态为"呼叫中"
      this.callPartner = user;
      this.callStatus = 'calling';
      this.isInCall = true;

      try {
        // 检查浏览器是否支持getUserMedia
        // getUserMedia是获取用户媒体的WebAPI，用于访问麦克风等设备
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          throw new Error('您的浏览器不支持语音通话功能');
        }

        // 获取本地媒体流
        // 配置音频参数：回声消除、噪声抑制和自动增益控制
        this.localStream = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,  // 回声消除减少通话中的声音回环
            noiseSuppression: true,  // 噪声抑制滤除背景噪音
            autoGainControl: true    // 自动增益控制保持音量均衡
          }
        });

        // 设置本地音频源
        // 将获取到的媒体流绑定到本地音频元素
        if (this.$refs.localAudio) {
          this.$refs.localAudio.srcObject = this.localStream;
        }

        // 创建WebRTC连接
        // 初始化对等连接对象，用于与远程用户建立通信
        this.createPeerConnection();

        // 添加本地媒体轨道到连接
        // 将本地音频流添加到对等连接中，准备发送给远程用户
        this.localStream.getTracks().forEach(track => {
          this.peerConnection.addTrack(track, this.localStream);
        });

        // 创建并发送通话请求
        // 创建SDP offer描述本地连接配置
        const offer = await this.peerConnection.createOffer();
        await this.peerConnection.setLocalDescription(offer);

        // 发送offer到对方
        // 通过WebSocket发送通话请求信息给目标用户
        this.socket.send(JSON.stringify({
            type: 'call_offer',
            targetId: user.senderid,
            callerId: this.currentUser.id,
            callerName: this.currentUser.nickname,
            sdp: offer
        }));

      } catch (error) {
        // 处理错误：记录错误并重置通话状态
        console.error('启动语音通话失败:', error);
        this.callStatus = '';
        this.callPartner = null;
      }
    },

    // 创建WebRTC连接
    // 描述：初始化WebRTC对等连接并设置相关事件处理程序
    // 参数：无参数
    // 返回值：无返回值
    // 核心逻辑：
    // > 步骤1: 配置并创建RTCPeerConnection对象
    // > 步骤2: 设置ICE候选者处理函数
    // > 步骤3: 设置接收远程媒体流的处理函数
    // > 步骤4: 设置连接状态变化的监控
    createPeerConnection() {
      // 配置ICE服务器
      // STUN服务器帮助获取公网IP地址，用于NAT穿透
      const configuration = {
        iceServers: [
          {urls: 'stun:stun.l.google.com:19302'}
        ]
      };

      // 创建对等连接对象
      this.peerConnection = new RTCPeerConnection(configuration);

      // 处理ICE候选者
      // 当发现新的网络连接路径时，将其发送给对方
      this.peerConnection.onicecandidate = (event) => {
        if (event.candidate) {
          this.socket.send(JSON.stringify({
              type: 'ice_candidate',
              targetId: this.callPartner.senderid,
              candidate: event.candidate
          }));
        }
      };

      // 处理远程媒体流
      // 当收到远程用户的音频流时，将其绑定到远程音频元素
      this.peerConnection.ontrack = (event) => {
        this.remoteStream = event.streams[0];
        if (this.$refs.remoteAudio) {
          this.$refs.remoteAudio.srcObject = this.remoteStream;
        }
        this.remoteStreamActive = true;
        this.isInCall = true;
        this.callStatus = 'connected';
      };

      // 处理ICE连接状态变化
      // 监控网络连接状态，在连接断开时结束通话
      this.peerConnection.oniceconnectionstatechange = () => {
        if (this.peerConnection.iceConnectionState === 'disconnected' ||
            this.peerConnection.iceConnectionState === 'failed') {
          this.endCall();
        }
      };
    },

    // 处理通话请求
    // 描述：处理来自其他用户的通话邀请，显示接听提示并处理用户响应
    // 参数：data - 包含呼叫者信息和SDP offer的数据对象
    // 返回值：无返回值
    // 核心逻辑：
    // > 步骤1: 保存呼叫方信息并设置通话状态
    // > 步骤2: 显示来电提示框让用户选择接听或拒绝
    // > 步骤3: 用户接听时，创建本地媒体流和WebRTC连接
    // > 步骤4: 生成并发送answer应答
    async handleCallOffer(data) {
      // 保存呼叫方信息
      // 记录发起通话的用户ID和昵称
      this.callPartner = {
        senderid: data.callerId,
        nickname: data.callerName
      };
      
      // 设置通话状态为"来电中"
      this.callStatus = 'incoming';
      this.isInCall = true;

      // 显示来电提示
      // 使用Element UI确认框让用户决定是否接听来电
      this.$confirm(`${data.callerName} 邀请您进行语音通话，是否接听？`, '来电', {
        confirmButtonText: '接听',
        cancelButtonText: '拒绝',
        type: 'info'
      }).then(async () => {
        // 用户同意接听
        try {
          // 检查浏览器是否支持getUserMedia
          if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            throw new Error('您的浏览器不支持语音通话功能');
          }

          // 获取本地媒体流
          // 配置与拨打方相同的音频参数
          this.localStream = await navigator.mediaDevices.getUserMedia({
            audio: {
              echoCancellation: true,
              noiseSuppression: true,
              autoGainControl: true
            }
          });

          // 设置本地音频源
          if (this.$refs.localAudio) {
            this.$refs.localAudio.srcObject = this.localStream;
          }

          // 创建WebRTC连接
          this.createPeerConnection();

          // 添加本地媒体轨道到连接
          this.localStream.getTracks().forEach(track => {
            this.peerConnection.addTrack(track, this.localStream);
          });

          // 设置远程描述
          // 将呼叫方发来的SDP offer设置为远程描述
          await this.peerConnection.setRemoteDescription(new RTCSessionDescription(data.sdp));

          // 创建并发送应答
          // 生成SDP answer响应offer
          const answer = await this.peerConnection.createAnswer();
          await this.peerConnection.setLocalDescription(answer);

          // 发送应答到对方
          // 通过WebSocket发送SDP answer给呼叫方
          this.socket.send(JSON.stringify({
              type: 'call_answer',
              targetId: this.callPartner.senderid,
              sdp: answer
          }));

          this.isInCall = true;

        } catch (error) {
          // 处理接听失败的情况
          console.error('接听语音通话失败:', error);
          this.callStatus = '';
          this.callPartner = null;
        }
      }).catch(() => {
        // 用户拒绝接听
        // 发送通话结束消息通知对方用户拒绝了通话
        this.socket.send(JSON.stringify({
            type: 'call_end',
            targetId: data.callerId
        }));
        
        // 重置通话状态
        this.callStatus = '';
        this.callPartner = null;
      });
    },

    // 处理通话应答
    // 描述：处理对方接受通话请求后发送的应答信息
    // 参数：data - 包含SDP answer的数据对象
    // 返回值：无返回值
    // 核心逻辑：
    // > 步骤1: 设置远程描述(SDP answer)
    // > 步骤2: 更新通话状态为已连接
    async handleCallAnswer(data) {
      try {
        // 设置远程描述
        // 将接收到的SDP answer设置为远程描述，完成WebRTC信令交换
        await this.peerConnection.setRemoteDescription(new RTCSessionDescription(data.sdp));
        
        // 更新通话状态
        this.isInCall = true;
      } catch (error) {
        // 处理错误情况
        console.error('处理通话应答失败:', error);
        this.endCall();
      }
    },

    // 处理ICE候选者
    // 描述：处理对方发送的ICE候选者信息，用于建立网络连接
    // 参数：data - 包含ICE候选者信息的数据对象
    // 返回值：无返回值
    // 核心逻辑：
    // > 步骤1: 检查是否存在有效的对等连接
    // > 步骤2: 添加ICE候选者到连接中
    async handleIceCandidate(data) {
      try {
        // 检查是否存在有效的对等连接
        if (this.peerConnection) {
          // 添加ICE候选者
          // ICE候选者包含网络连接信息，如IP地址、端口等
          // 用于尝试不同的网络路径建立P2P连接
          await this.peerConnection.addIceCandidate(new RTCIceCandidate(data.candidate));
        }
      } catch (error) {
        // 处理错误情况
        console.error('处理ICE候选者失败:', error);
      }
    },

    // 切换麦克风状态
    // 描述：切换本地音频流的静音状态
    // 参数：无参数
    // 返回值：无返回值
    // 核心逻辑：
    // > 步骤1: 检查本地媒体流是否存在
    // > 步骤2: 获取音频轨道并切换其启用状态
    toggleMute() {
      // 检查本地媒体流是否存在
      if (this.localStream) {
        // 获取音频轨道
        // WebRTC中的媒体流由多个轨道组成，这里获取音频轨道
        const audioTracks = this.localStream.getAudioTracks();
        
        // 确保存在音频轨道
        if (audioTracks.length > 0) {
          // 切换静音状态
          // 反转当前静音状态并更新音频轨道的启用状态
          this.isMuted = !this.isMuted;
          audioTracks[0].enabled = !this.isMuted;
        }
      }
    },

    // 结束通话
    // 描述：主动结束当前通话并通知对方
    // 参数：无参数
    // 返回值：无返回值
    // 核心逻辑：
    // > 步骤1: 通知对方通话结束
    // > 步骤2: 调用handleCallEnd处理本地通话结束流程
    endCall() {
      // 通知对方结束通话
      // 检查是否有通话伙伴且WebSocket连接是否有效
      if (this.callPartner && this.isConnected) {
        // 发送通话结束消息
        // 通过WebSocket向对方发送通话结束通知
        this.socket.send(JSON.stringify({
            type: 'call_end',
            targetId: this.callPartner.senderid
        }));
      }

      // 处理本地通话结束
      // 调用handleCallEnd方法清理资源并重置状态
      this.handleCallEnd();
    },

    // 处理通话结束
    // 描述：清理通话资源并重置通话相关状态
    // 参数：无参数
    // 返回值：无返回值
    // 核心逻辑：
    // > 步骤1: 关闭本地媒体流
    // > 步骤2: 关闭WebRTC连接
    // > 步骤3: 重置所有通话相关状态
    handleCallEnd() {
      // 关闭本地媒体流
      // 停止所有媒体轨道，释放麦克风等硬件资源
      if (this.localStream) {
        // 遍历并停止所有轨道
        this.localStream.getTracks().forEach(track => track.stop());
        this.localStream = null;
      }

      // 关闭WebRTC连接
      // 终止RTCPeerConnection连接，释放网络资源
      if (this.peerConnection) {
        this.peerConnection.close();
        this.peerConnection = null;
      }

      // 重置通话状态
      // 将所有通话相关状态变量恢复到初始值
      this.isInCall = false;      // 不再处于通话中
      this.callStatus = '';       // 清除通话状态提示
      this.callPartner = null;    // 清除通话伙伴信息
      this.remoteStream = null;   // 清除远程媒体流
      this.remoteStreamActive = false;  // 远程流不再活跃
    },

    // 生成消息ID
    // 描述：创建唯一的消息标识符
    // 参数：无参数
    // 返回值：字符串，表示唯一的消息ID
    // 核心逻辑：
    // > 步骤1: 使用时间戳生成基础字符串
    // > 步骤2: 添加随机字符串增加唯一性
    // > 步骤3: 合并两部分作为最终ID
    generateMessageId() {
      // 基于时间戳生成部分ID
      // 使用36进制(0-9a-z)表示时间戳，并填充到固定长度
      const timestamp = Date.now().toString(36).padStart(10, '0');
      
      // 生成随机字符串部分
      // 创建额外的随机部分增加唯一性，防止高并发下ID冲突
      const randomness = Math.random().toString(36).substr(2, 10);
      
      // 组合时间戳和随机部分
      // 返回完整的唯一ID字符串
      return timestamp + randomness;
    },

    // # == 心跳与重连模块 ==
    // 发送备用心跳包
    // 描述：当服务器不支持标准PING/PONG心跳时发送替代心跳消息
    // 参数：无参数
    // 返回值：无返回值
    // 核心逻辑：
    // > 步骤1: 检查WebSocket连接状态
    // > 步骤2: 发送特殊标记的普通消息作为心跳包
    sendFallbackHeartbeat() {
      // 检查WebSocket连接是否有效
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        // 发送一个无害的消息保持连接
        // 创建一个标准消息格式，但添加isHeartbeat标记
        // 这样服务器可以识别它是心跳而非普通消息
        this.socket.send(JSON.stringify({
          type: 'USER',          // 使用普通消息类型
          content: "",           // 内容为空
          senderid: this.currentUser.id,
          senderName: this.currentUser.nickname,
          isHeartbeat: true      // 标记为心跳消息
        }));
        console.log('发送备用心跳包');
      }
    },

    // 强制重新连接
    // 描述：用户主动触发的WebSocket重连操作
    // 参数：无参数
    // 返回值：无返回值
    // 核心逻辑：
    // > 步骤1: 关闭现有WebSocket连接
    // > 步骤2: 清除心跳和重连定时器
    // > 步骤3: 重置重连计数器并重新初始化连接
    forceReconnect() {
      // 关闭现有连接
      // 确保旧连接被正确关闭，避免资源泄露
      if (this.socket) {
        this.socket.close();
      }
      
      // 清除心跳和重连定时器
      // 停止所有相关的定时任务
      this.stopHeartbeat();
      if (this.reconnectTimer) {
        clearTimeout(this.reconnectTimer);
      }
      
      // 重置重连尝试计数
      // 从零开始重新计算重连次数
      this.reconnectAttempts = 0;
      
      // 显示重连中状态提示
      // 让用户知道系统正在尝试重新连接
      this.$message({
        message: '正在重新连接...',
        type: 'info'
      });
      
      // 重新初始化连接
      // 调用initSocket方法创建新的WebSocket连接
      this.initSocket();
    },
    
    // 刷新页面
    // 描述：在多次重连失败后提供刷新整个页面的选项
    // 参数：无参数
    // 返回值：无返回值
    // 核心逻辑：
    // > 步骤1: 显示确认对话框
    // > 步骤2: 用户确认后执行页面刷新
    refreshPage() {
      // 显示确认对话框
      // 让用户确认是否要刷新页面，避免意外操作
      this.$confirm('确定要刷新页面吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 用户确认后刷新页面
        // 重新加载整个页面，包括所有资源和脚本
        window.location.reload();
      }).catch(() => {
        // 用户取消操作，不做任何处理
      });
    },
  },

  // # == 组件生命周期模块 ==
  // 组件创建时的生命周期钩子
  // 描述：当Vue组件被创建时执行的初始化函数
  // 核心逻辑：
  // > 步骤1: 获取当前用户信息
  // > 步骤2: 初始化WebSocket连接
  // > 步骤3: 设置模拟数据(仅测试用)
  created() {
    // 获取当前用户信息
    // 从localStorage中读取已登录用户的数据
    const userData = localStorage.getItem('user');
    if (userData) {
      // 解析用户数据JSON并保存到组件状态
      this.currentUser = JSON.parse(userData);
    } else {
      // 如果未登录，提示用户并重定向到登录页
      // 聊天室需要用户已登录才能使用
      this.$message.warning('请先登录');
      this.$router.push({name: 'login'});
      return;
    }

    // 初始化WebSocket连接
    // 建立与聊天服务器的实时通信连接
    this.initSocket();

    // 模拟数据（测试用）
    // 在实际连接建立前，使用模拟数据填充界面
    this.onlineUsers = [
      {id: 1, nickname: '用户1', online: true, avatar: ''},
      {id: 2, nickname: '用户2', online: true, avatar: ''},
      {id: 3, nickname: '用户3', online: false, avatar: ''}
    ];

    this.messages = [
      {senderId: 1, senderName: '用户1', content: '大家好', sendTime: new Date().toISOString(), avatar: ''},
      {senderId: 2, senderName: '用户2', content: '你好，欢迎加入聊天室', sendTime: new Date().toISOString(), avatar: ''}
    ];
  },

  // 组件销毁前的生命周期钩子
  // 描述：当Vue组件被销毁前执行的清理函数
  // 核心逻辑：
  // > 步骤1: 结束任何进行中的通话
  // > 步骤2: 停止心跳机制
  // > 步骤3: 清除定时器
  // > 步骤4: 关闭WebSocket连接
  beforeDestroy() {
    // 结束任何进行中的通话
    // 确保在退出聊天室时释放所有媒体资源
    this.endCall();

    // 停止心跳
    // 清除心跳定时器，避免组件销毁后仍在执行
    this.stopHeartbeat();
    
    // 清除重连定时器
    // 防止组件销毁后仍尝试重连
    if (this.reconnectTimer) {
      clearTimeout(this.reconnectTimer);
    }
    
    // 关闭WebSocket连接
    // 优雅地关闭与服务器的连接
    if (this.socket) {
      this.socket.close();
    }
  }
};
</script>

<style scoped>
/* 聊天室容器样式 */
.chat-room-container {
  display: flex;
  height: calc(100vh - 120px);
  border-radius: 10px;
  overflow: hidden;
  margin: 20px;
  background-color: #fff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

/* 侧边栏样式 */
.sidebar {
  width: 280px;
  background-color: #f5f7fa;
  border-right: 1px solid #e6e6e6;
  display: flex;
  flex-direction: column;
}

/* 房间头部样式 */
.room-header {
  padding: 15px;
  border-bottom: 1px solid #e6e6e6;
}

/* 用户列表样式 */
.user-list {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.user-list h3 {
  margin-bottom: 15px;
  color: #606266;
  font-size: 16px;
}

/* 用户项样式 */
.user-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.user-item:hover {
  background-color: #ecf5ff;
}

/* 用户头像样式 */
.user-avatar {
  margin-right: 10px;
  flex-shrink: 0;
}

/* 用户信息样式 */
.user-info {
  flex: 1;
}

.user-name {
  font-weight: bold;
  margin-bottom: 2px;
}

/* 用户状态样式 */
.user-status {
  font-size: 12px;
  color: #909399;
}

.user-status.online {
  color: #67c23a;
}

/* 用户操作按钮样式 */
.user-actions {
  margin-left: 10px;
}

/* 聊天容器样式 */
.chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* 聊天头部样式 */
.chat-header {
  padding: 15px;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 通话状态样式 */
.call-status {
  background-color: #ecf5ff;
  padding: 5px 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
}

/* 消息容器样式 */
.message-container {
  flex: 1;
  padding: 15px;
  overflow-y: auto;
  background-color: #f9f9f9;
}

/* 消息项样式 */
.message-item {
  display: flex;
  margin-bottom: 15px;
}

/* 自己发送的消息样式 */
.self-message {
  flex-direction: row-reverse;
}

.self-message .message-content {
  background-color: #ecf5ff;
  margin-left: 0;
  margin-right: 10px;
  border-radius: 10px 2px 10px 10px;
}

/* 消息头像样式 */
.message-avatar {
  flex-shrink: 0;
  margin-right: 10px;
}

.self-message .message-avatar {
  margin-right: 0;
  margin-left: 10px;
}

/* 消息内容样式 */
.message-content {
  background-color: #fff;
  padding: 10px;
  border-radius: 2px 10px 10px 10px;
  margin-left: 10px;
  max-width: 70%;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
}

/* 消息发送者名称样式 */
.message-name {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 14px;
}

/* 消息文本样式 */
.message-text {
  word-break: break-word;
}

/* 消息时间样式 */
.message-time {
  font-size: 12px;
  color: #909399;
  margin-top: 5px;
  text-align: right;
}

/* 语音通话容器样式 */
.voice-call-container {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* 通话控制按钮样式 */
.call-controls {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

/* 远程用户名称样式 */
.remote-user {
  font-weight: bold;
  margin: 10px 0;
}

/* 聊天输入区域样式 */
.chat-input {
  padding: 15px;
  border-top: 1px solid #e6e6e6;
}

/* 输入操作按钮样式 */
.input-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

/* 连接状态样式 */
.connection-status {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 10px;
  gap: 5px;
}

.status-connected {
  color: #67c23a;
  background-color: #f0f9eb;
}

.status-disconnected {
  color: #f56c6c;
  background-color: #fef0f0;
}
</style> 